.horizontal-slider {
    height: 5vh;
    margin: auto;
  }
  .slider-track {
    position: relative;
    background: #7e7e7e;
  }
  .slider-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 10px solid #3774ff;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }
  .slider-track.slider-track-0 {
    background: #83a9ff;
  }
  .horizontal-slider .slider-track {
    top: 20px;
    height: 4px;
  }
  .horizontal-slider .slider-thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .dropdown-usrn{
    display: none;
  }
  .search-group{
    margin: 15px 0;
  }
  .row-stats {
    a {
      display: block;
    }
  }
  .mb-2-sm{
    margin-bottom: 20px !important;
  }
  #sidebar-toggle{
    background: $primary;
    display: inline-block;
    position: absolute;
    left: 6px;
    z-index: 99999;
    top: 15px;
    color: #fff;
    width: auto;
  }
  .search-group{
        margin-left: 40px;
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

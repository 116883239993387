.container {
  padding: 20px;
 
}


.scrollable-table {
  max-height: 700px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds max height */
}

.form-wrapper {
  border: 1px solid #d19000;
  padding: 20px;
  margin-bottom: 20px;
  background-color: whitesmoke;
}

.plots-wrapper {

  display: block;
  width: 100%;
  height: 900px;
  border-style: groove;
  margin-top: 20px;
  overflow-y: scroll;
  justify-content: center;
  padding: 20px;
  border: 1px solid #d19000;
  background-color: whitesmoke;
}


.form-wrapper h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.field-header {
  font-size: 20px;
  font-weight: bold;
  color: #003e51;
  text-transform: capitalize;
  margin-bottom: 10px;
  border-bottom: 2px solid #003e51;
  padding-bottom: 5px;
  /* Add any additional styling as needed */
}

.field-header-grouping {
  font-size: 20px;
  font-weight: bold;
  color: #003e51;
  text-transform: capitalize;
  /* Add any additional styling as needed */
}


.page-header {
  font-size: 40px;
  font-weight: bold;
  color: #d19000;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 15px;
  padding-bottom: 5px;
  /* Add any additional styling as needed */
}

.field-body {
  font-size: 18px;
  /* font-weight: bold; */
  color: #333333;
  text-transform: capitalize;
  /* Add any additional styling as needed */
}

.field-body2 {
  font-size: 18px;
  /* font-weight: bold; */
  color: #333333;
  /* Add any additional styling as needed */
}




.custom-button {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  text-transform: capitalize;
  margin-bottom: 10px;
  border-bottom: 2px solid #333333;
  padding-bottom: 5px;
  width: 190px; /* Adjust the width value as needed */
}

.table-icon {
  font-size: 28px; /* Adjust the size as needed */
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
}

/* #003e51 */
.menu-item-content {
    display: flex;
    align-items: center;
    color: #d19000
  }
  
  .menu-item-title {
    margin: 0;
    color: #d19000;
  }

  .container {
    height: 100vh; /* Set the height of the container to 100% of the viewport height */
    width: 100vw; /* Set the width of the container to 100% of the viewport width */
    display: flex; /* Optionally, use flexbox to control the positioning of child elements */
    align-items: center;
    justify-content: center;
  }